import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BUVLogin } from '../interfaces/user.interface';
import { UrlFactory } from '../utils/url-factory';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GenericFilter } from '../interfaces/genericFilter.interface';
import { Vecino } from '../interfaces/vecino.interface';

@Injectable({
  providedIn: 'root',
})
export class BuvService {
  vecinoAEditar$: BehaviorSubject<Vecino | null> =
    new BehaviorSubject<Vecino | null>(null);
  constructor(private _http: HttpClient) {}

  //post
  buvLogin(body: any): Observable<BUVLogin> {
    const URL = UrlFactory.buvLogin();
    return this._http.post<BUVLogin>(URL, body);

    return of({
      JWT: '',
      user: {
        dn: '',
        userPrincipalName: '',
        sAMAccountName: '',
        cn: '',
      },
      group: '',
    });
  }

  //get
  buvGetVecinos(filter: GenericFilter): Observable<Vecino[]> {
    const URL = UrlFactory.buvVecinos();
    const parameters = this._addQueryParams(filter);

    return this._http.get<Vecino[]>(URL, parameters);
  }

  //post
  buvCreateVecino(): void {
    const URL = UrlFactory.buvVecinos();
  }

  private _addQueryParams(filter: GenericFilter): { params: HttpParams } {
    let parameters = new HttpParams({});
    if (filter) {
      if (filter.id) {
        parameters = parameters.set('id', `${filter.id}`);
      }
      if (filter.numeroDocumento) {
        parameters = parameters.set(
          'numeroDocumento',
          `${filter.numeroDocumento}`
        );
      }
      if (filter.tipoDeDocumento) {
        parameters = parameters.set(
          'tipoDeDocumento',
          `${filter.tipoDeDocumento}`
        );
      }
      if (filter.nombre) {
        parameters = parameters.set('nombre', `${filter.nombre}`);
      }
      if (filter.apellido) {
        parameters = parameters.set('apellido', `${filter.apellido}`);
      }
      if (filter.genero) {
        parameters = parameters.set('genero', `${filter.genero}`);
      }

      if (filter.correoElectronico) {
        parameters = parameters.set(
          'correoElectronico',
          `${filter.correoElectronico}`
        );
      }

      if (filter.sexo) {
        parameters = parameters.set('sexo', `${filter.sexo}`);
      }

      if (filter.telefono) {
        parameters = parameters.set('telefono', `${filter.telefono}`);
      }

      // if (filter.domicilios && filter.domicilios.length > 0) {
      //   if (filter.domicilios.length === 1) {
      //   }
      //   //QUESTION: que pasa cuando tengo mas de un domicilio declarado, cual usar?
      //   //QUESTION: Como busco por domicilio?
      // }
    }

    return { params: parameters };
  }
}
